<template>
  <div>
    <!-- Collection Bar -->
    <div
      class="vx-row m-0 md:flex hidden boxBackgrounds BgWhiteBS collectionBar"
    >
      <div class="vx-col md:w-2/12 text-center">Image</div>
      <div class="vx-col md:w-2/12 text-center">Title</div>
      <div class="vx-col md:w-1/12 text-center">Product</div>
      <div class="vx-col md:w-2/12 text-center">Average Volume</div>
      <div class="vx-col md:w-1/12 text-center">Stone</div>
      <div class="vx-col md:w-1/12 text-center">Status</div>
      <div class="vx-col md:w-1/12 text-center">Edit</div>
      <div class="vx-col md:w-2/12 text-center">Add Product</div>
    </div>

    <!-- Collection -->
    <div class="vx-row m-0 boxBackgrounds BgWhiteBS collectionArea">
      <div class="vx-col md:w-2/12 sm:w-4/12 xs:w-4/12 text-center">
        <div style="position: relative">
          <input
            accept=".jpg"
            type="file"
            id="filesCollection"
            ref="filesCollection"
            v-on:change="HandleCollectionFileUpload"
            class="inputButton"
          />

          <label
            v-if="editCollectionEnable"
            class="mb-2"
            for="filesCollection"
            style="right: 0px; position: absolute"
          >
            <vs-icon icon="edit"> </vs-icon>
          </label>

          <img
            v-if="collectionLocalImage != null"
            style="width: 100%"
            :src="collectionLocalImage"
            alt=""
          />

          <img
            v-else
            style="width: 100%"
            v-lazy="collection.image_thumbnail"
            alt=""
          />
        </div>
      </div>
      <div class="vx-col md:w-2/12 sm:w-4/12 xs:w-4/12 text-center">
        <vs-input
          v-if="editCollectionEnable"
          style="text-align: left"
          color="dark"
          class="w-full"
          placeholder="Collection Title"
          v-model="collection.label"
        />

        <p v-else>
          {{ collection.label }}
        </p>
      </div>
      <div class="vx-col md:w-1/12 sm:w-1/12 xs:w-2/12 text-center">
        {{ collectionProductGroupCount }}
      </div>
      <div class="vx-col md:w-2/12 sm:w-2/12 xs:w-2/12 text-center">
        <p>
          {{ collectionAverageGram }}
        </p>
      </div>
      <div class="vx-col md:w-1/12 sm:w-1/12 xs:w-4/12 flex justify-center">
        <vs-checkbox v-if="editCollectionEnable" v-model="collection.has_stone">
        </vs-checkbox>
        <vs-icon
          v-else
          :icon="collection.has_stone ? 'done' : 'clear'"
          size="small"
          :color="collection.has_stone ? 'green' : 'red'"
        ></vs-icon>
      </div>
      <div class="vx-col md:w-1/12 sm:w-1/12 xs:w-4/12 flex justify-center">
        <vs-checkbox v-if="editCollectionEnable" v-model="collection.is_active">
        </vs-checkbox>
        <vs-icon
          v-else
          :icon="collection.is_active ? 'done' : 'clear'"
          size="small"
          :color="collection.is_active ? 'green' : 'red'"
        ></vs-icon>
      </div>
      <div class="vx-col md:w-1/12 sm:w-6/12 xs:w-4/12 flex justify-center">
        <vs-button
          :style="!editCollectionEnable ? '' : 'display : none !important'"
          radius
          color="#000"
          type="flat"
          icon="edit"
          size="medium"
          @click="editCollectionEnable = true"
        ></vs-button>
        <div
          :style="editCollectionEnable ? '' : 'display : none !important'"
          class="flex justify-between"
        >
          <vs-button
            class="mr-2"
            radius
            color="success"
            type="filled"
            icon="done"
            size="medium"
            @click="UpdateCollection"
          ></vs-button>
          <vs-button
            radius
            color="#000"
            type="filled"
            icon="clear"
            @click="editCollectionEnable = false"
          ></vs-button>
        </div>
      </div>
      <div class="vx-col md:w-2/12 sm:w-6/12 xs:w-4/12 flex justify-center">
        <vs-button
          v-if="!addProductEnable"
          radius
          color="#000"
          type="filled"
          icon="add"
          @click="ChangeAddProductEnable(true)"
        ></vs-button>
        <vs-button
          v-else
          radius
          color="#000"
          type="filled"
          icon="clear"
          @click="ChangeAddProductEnable(false)"
        ></vs-button>
      </div>

      <div class="vx-col w-full">
        <vs-alert
          class="m-2"
          :active="fileCollectionUploadFailed"
          color="danger"
        >
          {{ fileCollectionUploadFailedMessage }}
        </vs-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import axios from "axios";

export default {
  props: ["collection", "addProductEnable"],
  data() {
    return {
      collectionImage: null,
      editCollectionEnable: false,
      collectionLocalImage: null,
      fileCollectionUploadFailed: false,
      fileCollectionUploadFailedMessage: "",
      collectionAverageGram: this.collection.average_volume,
      collectionProductGroupCount: this.collection.product_groups_count,
    };
  },
  methods: {
    ChangeAddProductEnable(status) {
      this.$emit("ChangeAddProductEnable", status);
    },
    GetCollectionDetail() {
      Api.get(
        API.BASEURL +
          API.NOKTA_MARKET_PROVIDER_COLLECTIONS +
          this.collection.id +
          "/",
        this.HandleGetCollectionDetail
      );
    },
    HandleGetCollectionDetail(status, data) {
      if (status == 200) {
        this.collectionAverageGram = data.average_volume;
        this.collectionProductGroupCount = data.product_groups_count;
      }
    },
    HandleCollectionFileUpload() {
      if (typeof this.$refs.filesCollection.files[0] !== "undefined") {
        this.collectionImage = this.$refs.filesCollection.files[0];
        this.collectionLocalImage = URL.createObjectURL(this.collectionImage);
      } else {
        this.collectionImage = null;
        this.collectionLocalImage = null;
      }
    },
    UpdateCollection() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      this.fileCollectionUploadFailed = false;
      this.uploadURL =
        API.BASEURL +
        API.NOKTA_MARKET_PROVIDER_COLLECTIONS +
        this.collection.id +
        "/";
      let formData = new FormData();

      formData.append("title", this.collection.label);
      formData.append("is_active", this.collection.is_active);
      formData.append("has_stone", this.collection.has_stone);

      if (this.collectionImage != null) {
        formData.append("image", this.collectionImage);
      }

      axios
        .request({
          method: "patch",
          url: this.uploadURL,
          data: formData,
          timeout: 1000000,
          headers: {
            authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$vs.loading.close();

          if (response.status >= 200 && response.status < 300) {
            this.editCollectionEnable = false;
            this.collection.image_thumbnail = response.data.image_thumbnail;
            this.collection.label = response.data.title;
            this.$emit("ChangeProductGroupTitleTmp", response.data.title);

            this.collectionImage = null;
            this.$vs.notify({
              title: this.collection.label,
              text: "Collection Updated",
              color: "success",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();

          if (error.response.data.title) {
            this.fileCollectionUploadFailedMessage =
              error.response.data.title[0];
          } else if (error.response.data.image) {
            this.fileCollectionUploadFailedMessage =
              error.response.data.image[0];
          } else if (error.response.data.category) {
            this.fileCollectionUploadFailedMessage =
              error.response.data.category[0];
          }

          this.fileCollectionUploadFailed = true;
        });
    },
  },
  watch: {
    editCollectionEnable() {
      this.collectionLocalImage = null;
    },
  },
};
</script>

<style scoped>
.boxBackgrounds {
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}
.collectionBar {
  color: #bab6b6;
  font-weight: 600;
}
.inputButton + label {
  color: rgb(36, 33, 69);
  border: 1px solid rgb(36, 33, 69);
  background: transparent !important;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.collectionArea {
  font-weight: 700;
  font-size: 1.2rem;
}
</style>
