<template>
  <div class="vx-row m-0 p-0" style="align-items: center">
    <div class="vx-col md:w-1/12 xs:w-1/2 text-center">
      <img
        v-lazy="collection.image_thumbnail"
        alt=""
        style="width: 100%; border-radius: 50%"
      />
    </div>
    <div class="vx-col md:w-2/12 xs:w-1/2 text-center">
      {{ collection.label }}
    </div>
    <div class="vx-col md:w-2/12 xs:w-1/3 text-center">
      {{ collection.average_volume }}
    </div>
    <div class="vx-col md:w-1/12 xs:w-1/3 text-center">
      <vs-icon
        :icon="collection.has_stone ? 'done' : 'clear'"
        size="small"
        :color="collection.has_stone ? 'green' : 'red'"
      ></vs-icon>
    </div>
    <div class="vx-col md:w-1/12 xs:w-1/3 text-center">
      <vs-icon
        :icon="collection.is_active ? 'done' : 'clear'"
        size="small"
        :color="collection.is_active ? 'green' : 'red'"
      ></vs-icon>
    </div>
    <div class="vx-col md:w-1/12 xs:w-1/3 text-center">
      {{ collection.product_groups_count }}
    </div>
    <div class="vx-col md:w-4/12 md:block xs:hidden text-center"></div>
  </div>
</template>

<script>
export default {
  props: ["collection"],
};
</script>

<style></style>
