<template>
  <div>
    <div class="vx-row boxBackgrounds BgWhiteBS categoryBar m-0">
      <div class="vx-col md:w-1/6 md:block xs:hidden text-center">
        Category Image
      </div>
      <div class="vx-col md:w-1/3 md:block xs:hidden text-center">
        Category Title
      </div>
      <div class="vx-col md:w-1/6 md:block xs:hidden text-center">
        Collections
      </div>
      <div class="vx-col md:w-1/6 md:block xs:hidden text-center">
        Short Code
      </div>
      <div class="vx-col md:w-1/6 md:block xs:hidden text-center">
        Add Collection
      </div>
    </div>

    <div class="vx-row boxBackgrounds BgWhiteBS m-0 categoryArea">
      <div class="vx-col md:w-1/6 xs:w-1/2 text-center">
        <div style="position: relative">
          <input
            :accept="acceptFileTypes"
            type="file"
            id="filesCategory"
            ref="filesCategory"
            v-on:change="HandleCategoryFileUpload"
            class="inputButton"
          />

          <label
            class="mb-2"
            for="filesCategory"
            style="
              cursor: pointer;
              right: 0px;
              position: absolute;
              background-color: #fff9;
            "
            ><vs-icon icon="edit" color="#000"> </vs-icon
          ></label>
          <img style="width: 100%" v-lazy="category.image_thumbnail" alt="" />
        </div>
      </div>
      <div class="vx-col md:w-1/3 xs:w-1/2 text-center">
        {{ category.title }}
      </div>
      <div class="vx-col md:w-1/6 xs:w-1/3 text-center">
        {{ categoryCollectionCount }}
      </div>
      <div class="vx-col md:w-1/6 xs:w-1/3 justify-center flex">
        {{ category.short_code }}
      </div>
      <div class="vx-col md:w-1/6 xs:w-1/3 justify-center flex">
        <vs-button
          v-if="!addCollectionEnable"
          radius
          color="#000"
          type="filled"
          icon="add"
          @click="ChangeAddCollectionEnable(true)"
        ></vs-button>
        <vs-button
          v-else
          radius
          color="danger"
          type="filled"
          icon="clear"
          @click="ChangeAddCollectionEnable(false)"
        ></vs-button>
      </div>

      <div class="vx-col w-full ml-4 mt-5">
        <vs-alert :active="fileCategoryUploadFailed" color="danger">
          {{ fileCategoryUploadFailedMessage }}
        </vs-alert>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
import axios from "axios";

export default {
  props: ["category", "categoryCollectionCount", "addCollectionEnable"],
  data() {
    return {
      categoryImage: null,
      acceptFileTypes: ".jpg",
      fileCategoryUploadFailed: false,
      fileCategoryUploadFailedMessage: "",
    };
  },
  methods: {
    ChangeAddCollectionEnable(status) {
      this.$emit("addCollectionEnableChanged", status);
    },
    HandleCategoryFileUpload() {
      if (typeof this.$refs.filesCategory.files[0] !== "undefined") {
        this.categoryImage = this.$refs.filesCategory.files[0];
        this.UpdateCategory();
      } else {
        this.categoryImage = null;
      }
    },
    UpdateCategory() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      this.fileCategoryUploadFailed = false;
      this.uploadURL =
        API.BASEURL +
        API.NOKTA_MARKET_PROVIDER_CATEGORIES +
        this.category.id +
        "/";
      let formData = new FormData();

      if (this.categoryImage != null) {
        formData.append("image", this.categoryImage);
      }

      axios
        .request({
          method: "patch",
          url: this.uploadURL,
          data: formData,
          timeout: 1000000,
          headers: {
            authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$vs.loading.close();

          if (response.status >= 200 && response.status < 300) {
            this.category.image_thumbnail = response.data.image_thumbnail;
            this.$vs.notify({
              title: this.category.title,
              text: "Category Updated",
              color: "success",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();

          if (error.response.data.title) {
            this.fileCategoryUploadFailedMessage = error.response.data.title[0];
          } else if (error.response.data.image) {
            this.fileCategoryUploadFailedMessage = error.response.data.image[0];
          }

          this.fileCategoryUploadFailed = true;
        });
    },
  },
};
</script>

<style scoped>
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputButton + label {
  color: rgb(36, 33, 69);
  border: 1px solid rgb(36, 33, 69);
  display: inline-block;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.boxBackgrounds {
  padding: 10px;
  align-items: center;
}
.categoryBar {
  color: #bab6b6;
  font-weight: 600;
}
.categoryArea {
  font-weight: 700;
  font-size: 1.2rem;
}
</style>
