<template>
  <div class="vx-row m-0 mt-1 mb-1">
    <div class="vx-col xl:w-9/12 w-full p-0 m-0 flex items-center">
      <div class="vx-col md:w-1/12 xs:w-1/12 text-center">
        <p>-</p>
      </div>

      <div class="vx-col md:w-3/12 xs:w-3/12 flex justify-center">
        <p v-if="editableProduct == product.id">
          {{ product.title.split("-").at(0) }}-
        </p>

        <v-select
          v-if="editableProduct == product.id"
          v-model="selectedProductTypeItem"
          placeholder="Please Select Type"
          :options="productTypes"
        >
        </v-select>

        <vs-input
          v-if="editableProduct == product.id"
          style="text-align: left"
          color="dark"
          class="w-full"
          placeholder="Product Title"
          v-model="tempProductTitle"
        />

        <p v-else>
          {{ product.title }}
        </p>
      </div>
      <div class="vx-col md:w-1/12 xs:w-2/12 text-center p-0">
        <vs-input
          v-if="editableProduct == product.id"
          style="text-align: left"
          type="number"
          color="dark"
          class="w-full"
          placeholder="Volume"
          v-model="product.volume"
        />

        <p v-else>
          {{ product.volume }}
        </p>
      </div>
      <div class="vx-col md:w-1/12 xs:w-2/12 text-center">
        <vs-icon
          :icon="tmpIsApproved ? 'done' : 'clear'"
          size="small"
          :color="tmpIsApproved ? 'green' : 'red'"
        ></vs-icon>
      </div>
      <div class="vx-col md:w-1/12 xs:w-2/12 text-center">
        <vs-checkbox
          v-if="editableProduct == product.id"
          v-model="product.is_active"
        >
        </vs-checkbox>
        <vs-icon
          v-else
          :icon="product.is_active ? 'done' : 'clear'"
          size="small"
          :color="product.is_active ? 'green' : 'red'"
        ></vs-icon>
      </div>
      <div class="vx-col md:w-1/12 xs:w-2/12 flex justify-center">
        <vs-button
          v-if="editableProduct == product.id"
          radius
          color="success"
          type="filled"
          icon="done"
          @click.native="UpdateProduct(product)"
        ></vs-button>
        <vs-button
          v-else
          radius
          color="#000"
          type="flat"
          icon="edit"
          @click.native="
            editableProduct = product.id;
            selectedProductTypeItem = product.type;
          "
        ></vs-button>
      </div>
      <vs-divider class="w-full md:hidden m-3"></vs-divider>

      <div class="vx-col md:w-4/12 w-full text-center p-0">
        <vs-button
          v-if="editableProduct == product.id"
          radius
          class="ml-2"
          color="#000"
          type="filled"
          icon="clear"
          @click.native="editableProduct = null"
        ></vs-button>
        <div v-else class="vx-row p-0">
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (product.volume * weightMultipier.resin).toFixed(2) }}
          </div>
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (product.volume * weightMultipier.k14).toFixed(2) }}
          </div>
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (product.volume * weightMultipier.k18).toFixed(2) }}
          </div>
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (product.volume * weightMultipier.k21).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="tmpIsApproved"
      class="vx-col xl:w-3/12 w-full p-0 m-0 flex items-center"
    >
      <div class="vx-col w-full text-center p-0">
        <vs-button @click="IsApprovedFalse(product)">Updated</vs-button>
      </div>
    </div>

    <vs-alert
      class="m-2"
      :active="productFailedEnable && editableProduct == product.id"
      color="danger"
    >
      {{ productFailedMessage }}
    </vs-alert>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";

export default {
  props: ["product", "productTypes"],
  data() {
    return {
      selectedProductTypeItem: this.product.type,
      editableProduct: null,
      productFailedEnable: false,
      productFailedMessage: null,
      tempProductTitle: "",
      tmpIsApproved: this.product.is_approved,
    };
  },
  methods: {
    IsApprovedFalse(product) {
      let formData = new FormData();
      formData.append("is_approved", false);

      Api.patch(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT + product.id + "/",
        this.HandleIsApprovedFalse,
        formData
      );
    },
    HandleIsApprovedFalse(status, data) {
      if (status == 200) {
        this.tmpIsApproved = data.is_approved;
      }
    },
    UpdateProduct(product) {
      let formData = new FormData();

      if (this.selectedProductTypeItem.id) {
        this.selectedProductTypeItem = this.selectedProductTypeItem.id;
      }

      let title =
        product.title.split("-").at(0) +
        "-" +
        (this.selectedProductTypeItem == "\xa0" ||
        this.selectedProductTypeItem == ""
          ? ""
          : this.selectedProductTypeItem + "-") +
        this.tempProductTitle;

      if (this.tempProductTitle == "") {
        title = title.slice(0, -1);
      }

      if (this.selectedProductTypeItem != "\xa0") {
        formData.append("type", this.selectedProductTypeItem);
      } else {
        formData.append("type", "");
      }

      formData.append("is_active", product.is_active);
      formData.append("title", title);
      formData.append("volume", product.volume);
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.patch(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT + product.id + "/",
        this.HandleUpdateProduct,
        formData
      );
    },
    HandleUpdateProduct(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.$emit("UpdatedProduct", data.product_group);

        this.editableProduct = null;
      } else if (status == 400) {
        this.productFailedEnable = true;
        if (data.data.volume) {
          this.productFailedMessage = "Volume : " + data.data.volume[0];
        } else if (data.data.title) {
          this.productFailedMessage = data.data.title[0];
        } else if (data.data.type) {
          this.productFailedMessage = data.data.type[0];
        }
      }
    },
  },
  computed: {
    weightMultipier() {
      return this.$store.getters["noktaMarket/weightMultipier"];
    },
  },
  components: {
    vSelect,
  },
};
</script>

<style></style>
