<template>
  <div>
    <collectionDetail
      :collection="collection"
      :addProductEnable="addProductEnable"
      @ChangeAddProductEnable="ChangeAddProductEnable"
      @ChangeProductGroupTitleTmp="ChangeProductGroupTitleTmp"
      ref="collectionDetail"
    />

    <!-- Product Group Bar -->
    <div class="productBar">
      <div class="vx-row xl:w-9/12 w-full p-0 m-0">
        <div class="vx-col md:w-1/12 xs:w-1/12 text-center">Image</div>
        <div class="vx-col md:w-3/12 xs:w-3/12 text-center">Title</div>
        <div class="vx-col md:w-1/12 xs:w-2/12 text-center">Volume</div>
        <div class="vx-col md:w-1/12 xs:w-2/12 text-center">Appro.</div>
        <div class="vx-col md:w-1/12 xs:w-2/12 text-center">Status</div>
        <div class="vx-col md:w-1/12 xs:w-2/12 text-center">Edit</div>
        <div class="vx-col md:w-1/12 md:block xs:hidden text-center">Resin</div>
        <div class="vx-col md:w-1/12 md:block xs:hidden text-center">14K</div>
        <div class="vx-col md:w-1/12 md:block xs:hidden text-center">18K</div>
        <div class="vx-col md:w-1/12 md:block xs:hidden text-center">21K</div>
      </div>
    </div>
    <!-- Add Product Group -->
    <div
      class="vx-row m-0 boxBackgrounds BgWhiteBS mt-2 p-6"
      v-if="addProductEnable"
    >
      <div class="vx-col w-full mb-2 p-1">
        <p style="font-weight: 600">Add Product</p>
      </div>

      <div class="vx-row xl:w-9/12 w-full p-0 m-0">
        <div class="vx-col md:w-1/12 xs:w-2/12 p-1">
          <input
            multiple
            accept=".jpg"
            type="file"
            id="filesProductGroupMultiple"
            ref="filesProductGroupMultiple"
            v-on:change="HandleMultipleFileUpload"
            class="inputButton"
          />

          <label for="filesProductGroupMultiple">
            <vs-icon icon="add_a_photo" size="medium"> </vs-icon>
          </label>
        </div>

        <div
          class="vx-col md:w-3/12 xs:w-5/12 flex"
          style="align-items: center; font-weight: 600"
        >
          <p>{{ productGroupTitleTmp }}</p>
          <vs-input
            class="mt-4 mb-4 w-full"
            color="dark"
            placeholder="Title"
            v-model="productGroupTitle"
          />
        </div>
        <div class="vx-col md:w-2/12 xs:w-1/12"></div>

        <div class="vx-col md:w-1/12 xs:w-2/12 flex justify-center">
          <vs-checkbox v-model="productGroupStatus"></vs-checkbox>
        </div>
      </div>
      <div class="vx-row xl:w-3/12 w-full">
        <div class="vx-col w-full flex justify-end">
          <vs-button
            :disabled="productGroupSaveEnable"
            @click="CreateProductGroup()"
            >Save</vs-button
          >
        </div>
      </div>

      <div class="vx-col w-full"></div>

      <vs-alert
        class="m-2 w-full"
        :active="productGroupsFailedEnable"
        color="danger"
      >
        {{ productGroupsFailedMessage }}
      </vs-alert>

      <div class="vx-row mt-2">
        <img
          class="xl:w-1/6 p-2"
          v-for="(image, index) in multipleLocalFiles"
          v-lazy="image"
          :key="index"
          alt=""
        />
      </div>
    </div>

    <div
      v-if="productGroups.length == 0"
      class="vx-row flex justify-center mt-4"
      style="font-size: 1.2rem; font-weight: 600; color: red"
    >
      No added product
    </div>

    <div
      class="vx-row m-0 boxBackgrounds BgWhiteBS mt-2 pt-2 pb-2 p-0"
      v-for="productGroup in productGroups"
      :key="productGroup.id"
    >
      <!-- Product Group Start -->
      <div class="vx-row xl:w-9/12 w-full p-0 m-0" style="align-items: center">
        <div
          class="vx-col md:w-1/12 xs:w-1/12 text-center flex justify-center p-0"
        >
          <img
            v-if="SelectFirstImage(productGroup.images) != null"
            @click="ExpandImage(productGroup.id)"
            v-lazy="SelectFirstImage(productGroup.images)"
            alt=""
            style="width: 80%; border-radius: 50%; cursor: pointer"
          />

          <img
            v-else
            src="@/assets/images/add_image.png"
            style="width: 80%; border-radius: 50%; cursor: pointer"
            alt=""
            @click="ExpandImage(productGroup.id)"
          />
        </div>
        <div
          class="vx-col md:w-3/12 xs:w-3/12 text-center p-0"
          style="font-weight: 600"
        >
          <vs-input
            v-if="editableProductGroup == productGroup.id"
            style="text-align: left"
            color="dark"
            class="w-full"
            placeholder="Title"
            v-model="productGroup.title"
          />

          <p v-else>
            {{ productGroup.title }}
          </p>
        </div>
        <div
          class="vx-col md:w-1/12 xs:w-2/12 text-center p-0"
          style="font-weight: 600"
        >
          <p>{{ productGroup.volume }}</p>
        </div>
        <div class="vx-col md:w-1/12 xs:w-2/12 text-center"></div>
        <div class="vx-col md:w-1/12 xs:w-2/12 flex justify-center">
          <vs-checkbox
            v-if="editableProductGroup == productGroup.id"
            v-model="productGroup.is_active"
          >
          </vs-checkbox>
          <vs-icon
            v-else
            :icon="productGroup.is_active ? 'done' : 'clear'"
            size="small"
            :color="productGroup.is_active ? 'green' : 'red'"
          ></vs-icon>
        </div>
        <div class="vx-col md:w-1/12 xs:w-2/12 flex justify-center">
          <vs-button
            v-if="editableProductGroup != productGroup.id"
            radius
            color="#000"
            type="flat"
            icon="edit"
            @click="editableProductGroup = productGroup.id"
          ></vs-button>
          <vs-button
            v-if="editableProductGroup == productGroup.id"
            radius
            color="#000"
            type="filled"
            icon="done"
            @click="UpdateProductGroup(productGroup)"
          ></vs-button>
        </div>
        <vs-divider class="w-full md:hidden m-3"></vs-divider>
        <div class="vx-row md:w-4/12 xs:w-full p-0 m-0">
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (productGroup.volume * weightMultipier.resin).toFixed(2) }}
          </div>
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (productGroup.volume * weightMultipier.k14).toFixed(2) }}
          </div>
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (productGroup.volume * weightMultipier.k18).toFixed(2) }}
          </div>
          <div class="vx-col xs:w-3/12 text-center p-0">
            {{ (productGroup.volume * weightMultipier.k21).toFixed(2) }}
          </div>
        </div>
      </div>
      <vs-divider class="w-full md:hidden m-3"></vs-divider>
      <div class="vx-row xl:w-3/12 xs:w-full p-0">
        <div class="vx-col xl:w-9/12 sm:w-10/12 xs:w-9/12 flex justify-center">
          <vs-icon
            v-if="productGroup.id == expandedProductGroup"
            color="#D1D1D1"
            icon="expand_less"
            style="cursor: pointer"
            size="large"
            @click="expandedProductGroup = null"
          >
          </vs-icon>

          <vs-icon
            v-else
            color="#D1D1D1"
            icon="expand_more"
            style="cursor: pointer"
            size="large"
            @click="ExpandedOpen(productGroup.id)"
          >
          </vs-icon>
        </div>
        <div class="vx-col xl:w-3/12 sm:w-2/12 xs:w-3/12 flex justify-end">
          <vs-button
            v-if="addProductItemEnable != productGroup.id"
            radius
            color="#000"
            type="filled"
            icon="add"
            @click="
              addProductItemEnable = productGroup.id;
              productItem.tmpTitle = productGroup.title + '-';
              ExpandedOpen(productGroup.id);
            "
          ></vs-button>
          <vs-button
            v-if="addProductItemEnable == productGroup.id"
            radius
            color="#000"
            type="filled"
            icon="clear"
            @click="
              addProductItemEnable = null;
              expandedProductGroup = null;
            "
          ></vs-button>
        </div>
      </div>
      <!-- Product Group Update Alert -->
      <vs-alert
        class="m-2"
        :active="
          productGroupUpdateFailedEnable &&
          editableProductGroup == productGroup.id
        "
        color="danger"
      >
        {{ productGroupUpdateFailedMessage }}
      </vs-alert>
      <!-- Product Group Images  -->
      <div
        v-if="expandedImage == productGroup.id"
        class="vx-row w-full m-0 mt-4 p-2"
      >
        <image-group :productId="productGroup.id" @ChangeImage="ChangeImage" />
      </div>
      <!-- Product Group End -->

      <!-- Product Add  -->

      <div
        class="vx-row m-0 w-full"
        v-if="addProductItemEnable == productGroup.id"
      >
        <div
          class="vx-row xl:w-9/12 w-full p-0 m-0"
          style="align-items: center"
        >
          <div
            class="xs:w-1/12"
            style="text-align: right; font-weight: 600"
          ></div>
          <div
            class="vx-col md:w-3/12 sm:w-5/12 xs:w-7/12 flex"
            style="align-items: center; font-weight: 600"
          >
            {{ productGroup.title }}-
            <v-select
              v-model="selectedProductType"
              placeholder="Please Select Type"
              :options="productTypes"
            >
            </v-select>
            <p>-</p>
            <vs-input
              :disabled="selectedProductType.label == null"
              style="text-align: left"
              class="mt-4 mb-4 w-full"
              color="dark"
              v-model="productItem.title"
            />
          </div>
          <div class="vx-col md:w-1/12 sm:w-3/12 xs:w-4/12 p-0">
            <vs-input
              type="number"
              style="text-align: left"
              class="mt-4 mb-4 w-full"
              color="dark"
              placeholder="Volume"
              v-model="productItem.volume"
            />
          </div>
          <div class="md:hidden sm:w-6/12 xs:w-6/12"></div>
          <div
            class="vx-col md:w-1/12 sm:w-2/12 xs:w-2/12 flex justify-center"
          ></div>
          <div class="vx-col md:w-1/12 sm:w-2/12 xs:w-2/12 flex justify-center">
            <vs-checkbox v-model="productItem.status"></vs-checkbox>
          </div>
          <div class="vx-col md:w-1/12 xs:w-2/12 text-center"></div>
          <div class="vx-col md:w-1/12 md:block xs:hidden text-center">
            {{
              productItem.volume
                ? (productItem.volume * weightMultipier.resin).toFixed(2)
                : 0
            }}
          </div>
          <div class="vx-col x md:w-1/12 md:block xs:hidden text-center">
            {{
              productItem.volume
                ? (productItem.volume * weightMultipier.k14).toFixed(2)
                : 0
            }}
          </div>
          <div class="vx-col md:w-1/12 md:block xs:hidden text-center">
            {{
              productItem.volume
                ? (productItem.volume * weightMultipier.k18).toFixed(2)
                : 0
            }}
          </div>
          <div class="vx-col md:w-1/12 md:block xs:hidden text-center">
            {{
              productItem.volume
                ? (productItem.volume * weightMultipier.k21).toFixed(2)
                : 0
            }}
          </div>
        </div>
        <div
          class="vx-row xl:w-3/12 w-full m-0 xs:mt-2"
          style="align-items: center"
        >
          <div
            class="vx-col w-full flex justify-end mb-2"
            style="align-self: center"
          >
            <vs-button
              :disabled="productSaveEnable"
              @click="CreateProductItem(productGroup.id)"
              >Save</vs-button
            >
          </div>
        </div>

        <vs-alert
          class="m-2 w-full"
          :active="productItemFailedEnable"
          color="danger"
        >
          {{ productItemFailedMessage }}
        </vs-alert>
      </div>

      <div
        class="vx-col w-full text-center p-0"
        v-if="productGroup.id == expandedProductGroup"
      >
        <div v-if="isEmptyList" style="font-weight: 600; color: red">
          <vs-divider class="m-0 mt-1 mb-2"></vs-divider>
          Please Add Product
        </div>
        <div v-if="productGroupDetailShow">
          <div
            class="vx-row p-0 m-0"
            v-for="type in productsSubGroupList"
            :key="type"
          >
            <div class="vx-row xl:w-9/12 w-full p-0 m-0">
              <div
                style="background-color: #e6e6e6"
                class="vx-col md:w-1/12 xs:w-1/12 text-center pt-2 pb-2 p-0"
              >
                -
              </div>
              <div
                class="vx-col md:w-2/12 xs:w-3/12 text-center pt-2 pb-2 p-0"
                style="
                  color: #c89e82;
                  font-weight: 600;
                  background-color: #e6e6e6;
                "
              >
                <p>
                  {{ GetTypeLabel(type) }}
                </p>
              </div>
              <div
                style="background-color: #e6e6e6"
                class="vx-col md:w-5/12 xs:w-8/12 text-center pt-2 pb-2 p-0"
              ></div>

              <div
                class="vx-col md:w-4/12 text-center p-0 pt-2 pb-2"
                style="background-color: #e6e6e6; color: #000; font-weight: 600"
              >
                <div class="vx-row md:flex hidden p-0">
                  <div class="vx-col md:w-3/12 text-center p-0">Resin</div>
                  <div class="vx-col md:w-3/12 text-center p-0">14K</div>
                  <div class="vx-col md:w-3/12 text-center p-0">18K</div>
                  <div class="vx-col md:w-3/12 text-center p-0">21K</div>
                </div>
              </div>
            </div>

            <div class="vx-row xl:w-3/12 p-0 m-0">
              <div
                class="vx-col w-full"
                style="background-color: #e6e6e6"
              ></div>
            </div>

            <div
              class="w-full"
              v-for="(product, index) in ProductsFilteredType(type)"
              :key="product.id"
            >
              <productItem
                :style="index % 2 == 1 ? 'background-color: #f6f6f6' : ''"
                :product="product"
                :selectedProductType="selectedProductType"
                :productTypes="productTypes"
                @UpdatedProduct="UpdatedProduct"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <vs-pagination
      :style="productGroups.length == 0 ? 'display:none' : ''"
      class="mt-4"
      :total="totalPage"
      v-model="currentPage"
      @change="HandleChangePage(currentPage)"
    />
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import axios from "axios";
import vSelect from "vue-select";

import productItem from "./ProductItem.vue";
import imageGroup from "./ImageGroupList.vue";
import collectionDetail from "./CollectionDetail.vue";

export default {
  props: ["collection", "category"],
  data() {
    return {
      //Pagination
      currentPage: 1,
      totalPage: 1,

      addProductItemEnable: null,
      productGroupList: [],
      multipleFiles: [],
      multipleLocalFiles: [],

      editableProduct: null,

      editableProductGroup: null,
      productGroupUpdateFailedEnable: false,
      productGroupUpdateFailedMessage: null,

      expandedImage: null,
      productItem: {
        title: null,
        tmpTitle: null,
        weight: null,
        status: true,
      },
      productTypes: [],
      selectedProductType: {
        id: null,
        label: null,
      },
      productItemFailedEnable: false,
      productItemFailedMessage: null,
      isEmptyList: false,

      productGroupDetailShow: false,
      addProductEnable: false,
      expandedProductGroup: null,
      //Product Group
      productGroups: [],
      productGroupTitle: null,
      productGroupTitleTmp: this.collection.label,
      productGroupStatus: true,
      productGroupsFailedEnable: false,
      productGroupsFailedMessage: null,

      //Product Group Images
      uploadURL: null,
    };
  },
  methods: {
    ChangeImage(image) {
      if (image.length > 0) {
        let index = this.productGroups.findIndex(
          (e) => e.id == image[0].product_group
        );
        this.productGroups[index].images = image;
      }
    },
    ChangeProductGroupTitleTmp(title) {
      this.productGroupTitleTmp = title;
    },
    ChangeAddProductEnable(status) {
      this.addProductEnable = status;
    },
    UpdatedProduct(productGroup) {
      this.$refs.collectionDetail.GetCollectionDetail();
      this.GetProductGroups(this.collection.id);
      this.ExpandedOpen(productGroup);
    },
    HandleChangePage(page) {
      this.currentPage = page;
      this.GetProductGroups(this.collection.id);
    },
    ExpandImage(id) {
      if (this.expandedImage == id) {
        this.expandedImage = null;
      } else {
        this.expandedImage = id;
      }
    },
    GetTypeLabel(type) {
      return this.productTypes.filter((e) => e.label == type).length > 0
        ? this.productTypes.filter((e) => e.label == type)[0].id
        : " ";
    },
    ProductsFilteredType(type) {
      return this.productGroupList.filter((e) => e.type == type);
    },
    GetTypes() {
      Api.options(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT,
        this.HandleGetTypes
      );
    },
    HandleGetTypes(status, data) {
      if (status == 200) {
        this.productTypes = [
          {
            id: "\xa0",
            label: "\xa0",
          },
        ];

        this.selectedProductType = this.productTypes[0];

        data.actions.POST.type.choices.forEach((element) => {
          this.productTypes.push({
            id: element.display_name,
            label: element.value,
          });
        });
      }
    },
    HandleMultipleFileUpload(e) {
      this.multipleFiles = [...this.$refs.filesProductGroupMultiple.files];
      this.multipleLocalFiles = [];
      this.multipleFiles.forEach((element) => {
        this.multipleLocalFiles.push(URL.createObjectURL(element));
      });

      e.target.value = null;
    },
    UploadMultipleImages(productGroupId) {
      this.uploadURL =
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP_IMAGES;

      for (let index = 0; index < this.multipleFiles.length; index++) {
        const element = this.multipleFiles[index];

        let formData = new FormData();

        formData.append("product_group", productGroupId);
        formData.append("order", index + 10);
        formData.append("image", element);

        axios
          .request({
            method: "post",
            url: this.uploadURL,
            data: formData,
            timeout: 1000000,
            headers: {
              authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },

    CreateProductItem(productGroupId) {
      this.productItemFailedEnable = false;
      let formData = new FormData();
      let tmpTitle =
        this.productItem.tmpTitle +
        (this.selectedProductType.label == "\xa0"
          ? ""
          : this.selectedProductType.label + "-") +
        (this.productItem.title == null || this.productItem.title == ""
          ? ""
          : this.productItem.title);

      if (this.productItem.title == null || this.productItem.title == "") {
        tmpTitle = tmpTitle.slice(0, -1);
      }

      formData.append("is_active", this.productItem.status);
      if (this.selectedProductType.id != "\xa0") {
        formData.append(
          "type",
          String(this.selectedProductType.id).toUpperCase()
        );
      }

      formData.append("product_group", productGroupId);
      formData.append("title", tmpTitle);
      formData.append("volume", this.productItem.volume);

      Api.post(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT,
        this.HandleCreateProductItem,
        formData
      );
    },
    HandleCreateProductItem(status, data) {
      if (status >= 200 && status < 300) {
        this.GetProductGroups(this.collection.id);
        this.productGroupList.push(data);

        this.ExpandedOpen(data.product_group);
        this.$refs.collectionDetail.GetCollectionDetail();

        this.$vs.notify({
          title: "Successfull",
          text: data.title + " added!",
          color: "success",
        });
      } else {
        this.productItemFailedEnable = true;
        if (data.data.weight) {
          this.productItemFailedMessage = "Weight : " + data.data.weight[0];
        } else if (data.data.title) {
          this.productItemFailedMessage = "Title : " + data.data.title[0];
        }
      }
    },

    ExpandedOpen(id) {
      this.expandedProductGroup = id;
      this.GetProductGroupsDetail(id);
    },
    GetProductGroupsDetail(id) {
      this.productGroupDetailShow = false;
      this.isEmptyList = false;
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      var params = { product_group: id };

      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT,
        this.HandleGetProductGroupsDetail,
        params
      );
    },
    HandleGetProductGroupsDetail(status, data) {
      this.$vs.loading.close();

      if (status >= 200 && status < 300) {
        this.productGroupList = data.results;

        if (this.productGroupList.length == 0) {
          this.isEmptyList = true;
        }

        this.productGroupDetailShow = true;
      }
    },
    SelectFirstImage(images) {
      var firstImage;
      if (images.length > 0) {
        firstImage = images[0];
        images.forEach((element) => {
          if (element.order < firstImage.order) {
            firstImage = element;
          }
        });
        return firstImage.image_thumbnail;
      }
      return null;
    },
    UpdateProductGroup(product) {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      this.productGroupUpdateFailedEnable = false;

      let formData = new FormData();

      formData.append("title", product.title);
      formData.append("is_active", product.is_active);

      Api.patch(
        API.BASEURL +
          API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP +
          product.id +
          "/",
        this.HandleUpdateProductGroup,
        formData
      );
    },
    HandleUpdateProductGroup(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.editableProductGroup = null;
        this.GetProductGroups(this.collection.id);
        this.$vs.notify({
          title: "Product Group Updated",
          text: data.title,
          color: "success",
        });
      } else {
        if (data.data.title[0]) {
          this.productGroupUpdateFailedMessage = data.data.title[0];
        }

        this.productGroupUpdateFailedEnable = true;
      }
    },
    GroupProductImagesOrderCompare(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },
    CreateProductGroup() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      this.productGroupsFailedEnable = false;

      let formData = new FormData();

      formData.append("collection", this.collection.id);
      formData.append(
        "title",
        this.productGroupTitleTmp + this.productGroupTitle
      );
      formData.append("is_active", this.productGroupStatus);

      Api.post(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP,
        this.HandleCreateProductGroup,
        formData
      );
    },
    HandleCreateProductGroup(status, data) {
      this.$vs.loading.close();

      if (status == 201) {
        this.GetProductGroups(this.collection.id);
        this.addProductEnable = false;
        this.productGroupTitleTmp = this.collection.label;
        this.multipleLocalFiles = [];
        this.productGroupTitle = null;

        this.UploadMultipleImages(data.id);
        this.$refs.collectionDetail.GetCollectionDetail();

        this.addProductItemEnable = data.id;
        this.productItem.tmpTitle = data.title + "-";
        this.expandedProductGroup = null;
        this.$vs.notify({
          title: "Product Group Added",
          text: data.label + " Added",
          color: "success",
        });
        this.addCollectionEnable = false;
      } else if (status == 400) {
        this.productGroupsFailedEnable = true;
        if (data.data.title) {
          this.productGroupsFailedMessage = data.data.title[0];
        }
        if (data.data.weight) {
          this.productGroupsFailedMessage = data.data.weight[0];
        }
      }
    },

    GetProductGroups(collectionId) {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      var params = { collection: collectionId };

      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }
      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP,
        this.HandleGetProductGroups,
        params
      );
    },
    HandleGetProductGroups(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.productGroups = data.results;
      }

      this.totalPage = parseInt((data.count / 50).toFixed(0)) + 1;

      this.productGroups.forEach((element) => {
        element.expanded = false;
      });
    },
  },
  computed: {
    weightMultipier() {
      return this.$store.getters["noktaMarket/weightMultipier"];
    },
    productsSubGroupList() {
      var tempList = [];

      if (this.productGroupList.length > 0) {
        tempList.push(this.productGroupList[0].type);
      } else {
        return [];
      }

      this.productGroupList.forEach((element) => {
        var index = tempList.findIndex((e) => e == element.type);
        if (index == -1) {
          tempList.push(element.type);
        }
      });

      return tempList;
    },
    productGroupSaveEnable() {
      if (this.productGroupTitle == null || this.productGroupTitle == "") {
        return true;
      } else {
        return false;
      }
    },
    productSaveEnable() {
      if (this.productItem.volume == "" || this.productItem.volume == null) {
        return true;
      } else return false;
    },
  },
  created() {
    this.GetTypes();
  },
  watch: {
    expandedImage(newValue) {
      if (newValue != null) {
        this.addProductItemEnable = null;
      }
    },
    expandedProductGroup(newValue) {
      if (newValue != null && this.addProductItemEnable != newValue) {
        this.addProductItemEnable = null;
      }
    },
    addProductItemEnable(newValue) {
      this.productItemFailedEnable = false;
      this.productItem.status = true;

      if (newValue != null) {
        this.expandedImage = null;

        if (this.expandedProductGroup != newValue) {
          this.expandedProductGroup = null;
        }
      }
    },
  },
  components: {
    imageGroup,
    vSelect,
    productItem,
    collectionDetail,
  },
};
</script>

<style scoped>
.productBar {
  background-color: #c7c7c7;
  color: white;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}
.boxBackgrounds {
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}
.inputButton + label {
  color: rgb(36, 33, 69);
  border: 1px solid rgb(36, 33, 69);
  background: transparent !important;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
<style>
.con-vs-dialog {
  z-index: 54005;
}

.vs--searchable .vs__dropdown-toggle {
  min-height: 40px;
  min-width: 30px;
  cursor: pointer;
}
.vs__actions {
  display: none;
}
</style>
