<template>
  <div>
    <div class="breadcrumbBackground BgWhiteBS vx-row mt-2 m-0 p-2">
      <div
        :class="selectedCategory != null ? 'clickAble' : ''"
        @click="ClearCategory"
      >
        Categories
      </div>
      <div v-if="selectedCategory != null" class="ml-2 mr-2">></div>
      <div
        v-if="selectedCategory != null"
        :class="selectedCollection != null ? 'clickAble' : ''"
        @click="ClearCollection"
      >
        {{ selectedCategory.title }}
      </div>
      <div v-if="selectedCollection != null" class="ml-2 mr-2">></div>
      <div
        v-if="selectedCollection != null"
        :class="selectedProduct != null ? 'clickAble' : ''"
      >
        {{ selectedCollection.label }}
      </div>
    </div>

    <vs-divider class="m-0"></vs-divider>
    <div class="vx-col">
      <categories
        v-if="selectedCategory == null"
        @changeCategory="ChangedCategory($event)"
      />
      <collections
        v-if="selectedCategory != null && selectedCollection == null"
        :category="selectedCategory"
        @changeCollection="ChangedCollection($event)"
      />
      <product
        v-if="selectedCollection != null && selectedProduct == null"
        :collection="selectedCollection"
        :category="selectedCategory"
      />
    </div>
  </div>
</template>

<script>
import categories from "./components/Category.vue";
import collections from "./components/Collections.vue";
import product from "./components/ProductGroup.vue";

export default {
  data() {
    return {
      selectedCategory: null,
      selectedCollection: null,
      selectedProduct: null,
    };
  },
  methods: {
    ClearCategory() {
      this.selectedCategory = null;
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      delete params.category;
      delete params.collection;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    ClearCollection() {
      this.selectedCollection = null;
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      delete params.collection;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    ChangedCategory(newCategory) {
      this.selectedCategory = newCategory;
    },
    ChangedCollection(newCollection) {
      this.selectedCollection = newCollection;
    },
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  watch: {
    query(newValue) {
      if (!newValue.hasOwnProperty("category")) {
        this.selectedCategory = null;
      }
      if (!newValue.hasOwnProperty("collection")) {
        this.selectedCollection = null;
      }
    },
    selectedCategory(newValue) {
      if (newValue == null) {
        this.selectedCollection = null;
      }
    },
    selectedCollection(newValue) {
      if (newValue == null) {
        this.selectedProduct = null;
      }
    },
  },
  components: {
    categories,
    collections,
    product,
  },
};
</script>

<style scoped>
.breadcrumbBackground {
  font-size: 1.5rem;
}
.clickAble {
  cursor: pointer;
  font-weight: 600;
}
.clickAble:hover {
  color: #c89e82;
}
</style>

<style>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}
.v-select .vs__dropdown-toggle .vs__search {
  display: none;
}

.vs__dropdown-toggle {
  background: white;
}

.vs__dropdown-option--highlight {
  background: #c89e82 !important;
  color: #fff;
}
</style>
