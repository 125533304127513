<template>
  <div class="w-full">
    <div>
      <input
        multiple
        accept=".jpg"
        type="file"
        id="filesProductGroup"
        ref="filesProductGroup"
        v-on:change="HandleFileUpload"
        class="inputButton"
      />

      <label for="filesProductGroup">Add Image</label>
    </div>

    <vs-alert
      class="mt-2 w-full"
      :active="imageUploadFailedEnable"
      color="danger"
    >
      {{ imageUploadFailedMessage }}
    </vs-alert>

    <draggable
      v-model="productGroupImages"
      @start="drag = true"
      @end="drag = false"
      class="flex flex-wrap"
    >
      <div
        class="image xl:w-1/6 lg:w-1/5 md:w-1/4 sm:w-1/3 xs:w-1/2 p-2"
        v-for="element in productGroupImages"
        :key="element.id"
      >
        <div style="position: relative">
          <vs-icon
            class="closeButton"
            style="right: 0px; position: absolute"
            icon="clear"
            color="danger"
            size="small"
            @click="DeleteImage(element.id)"
          >
          </vs-icon>
          <img style="width: 100%" :src="element.image_thumbnail" alt="" />
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import axios from "axios";

import draggable from "vuedraggable";

export default {
  data() {
    return {
      imageUploadFailedEnable: false,
      imageUploadFailedMessage: null,
      productGroupImages: [],
      changeCounter: 0,
      deleteImage: require("@/assets/images/pages/tb.png"),
      productGroupImage: null,
      uploadURL: null,
      multipleFiles: [],
    };
  },
  props: ["productId"],
  methods: {
    HandleFileUpload(e) {
      this.multipleFiles = [...this.$refs.filesProductGroup.files];
      this.AddProductGroupImages();
      e.target.value = null;
    },
    AddProductGroupImages() {
      this.imageUploadFailedEnable = false;
      this.uploadURL =
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP_IMAGES;
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      for (let index = 0; index < this.multipleFiles.length; index++) {
        const element = this.multipleFiles[index];
        let formData = new FormData();

        formData.append("order", this.productGroupImages.length + 10);
        formData.append("product_group", this.productId);
        formData.append("image", element);

        axios
          .request({
            method: "post",
            url: this.uploadURL,
            data: formData,
            timeout: 1000000,
            headers: {
              authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.filePersent = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            if (index == this.multipleFiles.length - 1) {
              this.GetProductGroupImages();
              this.$vs.loading.close();
            }

            if (response.status >= 200 && response.status < 300) {
              this.productGroupImage = null;
              this.filePersent = 0;
            }
          })
          .catch((error) => {
            if (index == this.multipleFiles.length - 1) {
              this.$vs.loading.close();
            }

            if (error.response.data.image) {
              this.imageUploadFailedMessage = error.response.data.image[0];
            }

            this.filePersent = -1;
            this.imageUploadFailedEnable = true;
          });
      }
    },

    UpdateProductImageOrder() {
      if (this.productGroupImages.length > 0) {
        this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

        this.productGroupImages.forEach((e, index) => {
          let formData = new FormData();

          formData.append("order", index);
          Api.patch(
            API.BASEURL +
              API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP_IMAGES +
              e.id +
              "/",
            this.HandleUpdateProductImageOrder,
            formData
          );
        });
      }
    },
    HandleUpdateProductImageOrder() {
      this.changeCounter++;
      if (this.changeCounter == this.productGroupImages.length) {
        this.$vs.loading.close();
        this.changeCounter = 0;
        this.$emit("ChangeImage", this.productGroupImages);
      }
    },
    GetProductGroupImages(deleting = false) {
      if (!deleting) {
        this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      }

      var params = { product_group: this.productId };
      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP_IMAGES,
        this.HandleGetProductGroupImages,
        params
      );
    },
    HandleGetProductGroupImages(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.productGroupImages = data.results.sort(
          this.GroupProductImagesOrderCompare
        );
        this.$emit("ChangeImage", this.productGroupImages);
      }
    },
    GroupProductImagesOrderCompare(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },
    DeleteImage(id) {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.delete(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_PRODUCT_GROUP_IMAGES + id + "/",
        this.HandleDeleteImage
      );
    },
    HandleDeleteImage(status) {
      if (status == 204) {
        this.GetProductGroupImages(true);
      } else {
        this.$vs.loading.close();
      }
    },
  },
  components: {
    draggable,
  },
  watch: {
    productGroupImages(newValue, oldValue) {
      if (oldValue.length == newValue.length) {
        this.UpdateProductImageOrder();
      }
    },
  },
  created() {
    this.GetProductGroupImages();
  },
};
</script>

<style scoped>
.closeButton:hover {
  background-color: #c6c6c6;
}
.image {
  cursor: pointer;
}
.inputButton + label {
  color: rgba(var(--vs-primary), 1);
  border: 1px solid rgba(var(--vs-primary), 1);
  background: transparent !important;
  display: inline-block;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
