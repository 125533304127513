<template>
  <div>
    <div
      class="vx-row m-0"
      style="
        background-color: white;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      "
    >
      <div
        class="vx-col xl:w-1/5 lg:w-1/4 md:w-1/3 sm:w-1/2 w-full mb-4 p-2"
        v-for="category in categories"
        :key="category.id"
      >
        <div class="boxBackgrounds BgWhiteBS" @click="SendCategory(category)">
          <img v-lazy="category.image_thumbnail" alt="" style="width: 100%" />

          <p
            class="mb-2"
            style="color: #121212; text-align: center; font-weight: 600"
          >
            {{ category.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    SendCategory(category) {
      this.$emit("changeCategory", category);
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      params.category = category.id;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },

    GetCategories() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_CATEGORIES,
        this.HandleGetCategories
      );
    },
    HandleGetCategories(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.categories = data;
        if (this.$route.query.category != null) {
          let index = this.categories.findIndex(
            (e) => e.id == this.$route.query.category
          );
          this.SendCategory(this.categories[index]);
        }
      }
    },
  },

  created() {
    this.GetCategories();
  },
};
</script>

<style scoped>
.boxBackgrounds {
  border: 1px solid #dedede;
  border-radius: 5px;
  cursor: pointer;
}
</style>
