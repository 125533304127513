<template>
  <div>
    <categoryDetail
      :category="category"
      :categoryCollectionCount="categoryCollectionCount"
      :addCollectionEnable="addCollectionEnable"
      @addCollectionEnableChanged="AddCollectionEnableChanged"
    />

    <div class="vx-row collectionBar m-0">
      <div class="vx-col md:w-1/12 md:block xs:hidden text-center">Image</div>
      <div class="vx-col md:w-2/12 md:block xs:hidden text-center">Title</div>
      <div class="vx-col md:w-2/12 md:block xs:hidden text-center">Volume</div>
      <div class="vx-col md:w-1/12 md:block xs:hidden text-center">Stone</div>
      <div class="vx-col md:w-1/12 md:block xs:hidden text-center">Status</div>
      <div class="vx-col md:w-1/12 md:block xs:hidden text-center">Product</div>
      <div class="vx-col md:w-4/12 w-full flex justify-end">
        <vs-input
          icon="search"
          placeholder="Search"
          v-model="searchValue"
          @keydown.enter="GetCollection"
          style="color: black"
        />
      </div>
    </div>

    <div
      class="vx-row boxBackgrounds BgWhiteBS m-0 mt-2 p-6"
      v-if="addCollectionEnable"
    >
      <div class="vx-col w-full mb-2 p-1">
        <p style="font-weight: 600">Add Collection</p>
      </div>

      <div class="vx-col md:w-1/12 xs:w-2/12 p-1">
        <input
          :accept="acceptFileTypes"
          type="file"
          id="filesCollection"
          ref="filesCollection"
          v-on:change="HandleCollectionFileUpload"
          class="inputButton"
        />

        <label for="filesCollection" style="cursor: pointer">
          <vs-icon icon="add_a_photo" color="#000" size="medium"> </vs-icon>
        </label>

        <vs-progress
          v-if="filePersent"
          class="mb-4 mt-4"
          :height="12"
          :percent="filePersent"
          :color="fileUploadFailed ? 'danger' : 'primary'"
        ></vs-progress>
      </div>

      <div
        class="vx-col md:w-2/12 sm:w-6/12 xs:w-8/12 flex"
        style="align-items: center; font-weight: 600"
      >
        <p>{{ collectionTitleTmp }}</p>
        <vs-input
          class="mt-4 mb-4 w-full"
          color="dark"
          placeholder="Collection Name"
          v-model="collectionTitle"
        />
      </div>
      <div class="vx-col md:w-2/12 md:block hidden"></div>
      <div class="vx-col md:w-1/12 sm:w-1/12 xs:w-2/12 flex justify-center">
        <vs-checkbox v-model="collectionStone"></vs-checkbox>
      </div>

      <div class="vx-col md:w-1/12 sm:w-1/12 xs:w-2/12 flex justify-center">
        <vs-checkbox v-model="collectionStatus"></vs-checkbox>
      </div>
      <div class="vx-col md:w-5/12 sm:w-3/12 w-full flex justify-end">
        <vs-button :disabled="collectionSaveEnable" @click="CreateCollection()"
          >Save</vs-button
        >
      </div>
      <div class="vx-col md:w-2/12 xs:w-1/2 flex justify-center">
        <img :src="collectionTmpImage" alt="" style="width: 100%" />
      </div>
      <vs-alert :active="fileUploadFailed" color="danger">
        {{ fileUploadFailedMessage }}
      </vs-alert>
    </div>

    <div
      v-if="collections.length == 0"
      class="vx-row flex justify-center mt-4"
      style="font-size: 1.2rem; font-weight: 600; color: red"
    >
      No added collections
    </div>

    <div
      class="boxBackgrounds BgWhiteBS mt-2"
      style="cursor: pointer"
      v-for="collection in collections"
      :key="collection.id"
      @click="SendCollection(collection)"
    >
      <collectionItem :collection="collection" />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import axios from "axios";

import collectionItem from "./CollectionItem.vue";
import categoryDetail from "./CategoryDetail.vue";

export default {
  props: ["category"],
  data() {
    return {
      categoryCollectionCount: this.category.collection_count,
      acceptFileTypes: ".jpg",
      collections: [],
      addCollectionEnable: false,

      collectionTitle: null,
      collectionTitleTmp: this.category.short_code,
      collectionImage: null,
      collectionStone: false,
      collectionStatus: true,
      collectionTmpImage: null,

      filePersent: 0,
      fileUploadFailed: false,
      fileUploadFailedMessage: "",

      searchValue: "",
    };
  },
  methods: {
    AddCollectionEnableChanged(status) {
      this.addCollectionEnable = status;
    },
    SendCollection(collection) {
      this.$emit("changeCollection", collection);
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      params.collection = collection.id;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },

    CreateCollection() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      this.fileUploadFailed = false;
      this.uploadURL = API.BASEURL + API.NOKTA_MARKET_PROVIDER_COLLECTIONS;
      let formData = new FormData();

      formData.append("category", this.category.id);
      formData.append("title", this.collectionTitleTmp + this.collectionTitle);
      formData.append("image", this.collectionImage);
      formData.append("has_stone", this.collectionStone);
      formData.append("is_active", this.collectionStatus);

      axios
        .request({
          method: "post",
          url: this.uploadURL,
          data: formData,
          timeout: 1000000,
          headers: {
            authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.filePersent = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          this.$vs.loading.close();

          if (response.status >= 200 && response.status < 300) {
            this.$vs.notify({
              title: this.collectionTitleTmp + this.collectionTitle,
              text: "Collection Updated",
              color: "success",
            });
            this.categoryCollectionCount += 1;
            this.GetCollection();
            this.addCollectionEnable = false;
            this.collectionTitleTmp = this.category.short_code;
            this.collectionTitle = null;
            this.collectionImage = null;
            this.collectionTmpImage = null;
            this.filePersent = 0;
            this.collectionStone = false;
            this.collectionStatus = true;
            this.$emit("getCategories");
          }
        })
        .catch((error) => {
          this.$vs.loading.close();

          if (error.response.data.title) {
            this.fileUploadFailedMessage = error.response.data.title[0];
          } else if (error.response.data.image) {
            this.fileUploadFailedMessage = error.response.data.image[0];
          }

          this.filePersent = -1;
          this.fileUploadFailed = true;
        });
    },
    GetCollection() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      var params = { category: this.category.id };

      if (this.searchValue != "") {
        params.search = this.searchValue;
      }
      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_COLLECTIONS,
        this.HandleGetCollection,
        params
      );
    },
    HandleGetCollection(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.collections = this.changeTitleToLabel(data);
        if (this.$route.query.collection != null) {
          let index = this.collections.findIndex(
            (e) => e.id == this.$route.query.collection
          );
          this.SendCollection(this.collections[index]);
        }
      }
    },
    changeTitleToLabel(list) {
      var i;
      for (i = 0; i < list.length; i++) {
        list[i].label = list[i]["title"];
        delete list[i].title;
      }
      return list;
    },
    HandleCollectionFileUpload() {
      if (typeof this.$refs.filesCollection.files[0] !== "undefined") {
        this.collectionImage = this.$refs.filesCollection.files[0];
        this.collectionTmpImage = URL.createObjectURL(this.collectionImage);
      } else {
        this.collectionImage = null;
        this.collectionTmpImage = null;
      }
    },
  },
  created() {
    this.GetCollection();
  },
  computed: {
    collectionSaveEnable() {
      if (this.collectionTitle == null || this.collectionTitle == "") {
        return true;
      } else if (this.collectionImage == null) {
        return true;
      } else {
        return false;
      }
    },
  },

  components: {
    collectionItem,
    categoryDetail,
  },
};
</script>

<style scoped>
.collectionBar {
  background-color: #c7c7c7;
  color: white;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}
.boxBackgrounds {
  padding: 10px;
  align-items: center;
}
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputButton + label {
  color: rgb(36, 33, 69);
  border: 1px solid rgb(36, 33, 69);
  display: inline-block;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
</style>

<style>
.vs-input--icon {
  font-size: 1.5rem;
}
</style>
